






import Vue from 'vue';
import Component from 'vue-class-component';

import { DataApplication } from '@/models/data/models';

@Component({})
export default class DataApplicationList extends Vue {
  DataApplication = DataApplication;
  get filter() {
    return {
      organisation: this.$store.getters['global/organisation'].id,
    };
  }
}
